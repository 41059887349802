// Copyright (C) 2017-2022 Smart code 203358507

.video-container {
    .video {
        width: 100%;
        height: 100%;

        * {
            font-size: inherit;
        }
    }
}