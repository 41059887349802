// Copyright (C) 2017-2022 Smart code 203358507

:global(.animation-fade-in) {
    :local {
        animation-name: fade-in;
    }

    animation-timing-function: ease-in-out;
    animation-duration: 100ms;
}

@keyframes fade-in {
    0% {
        opacity: 0.6;
        transform: translateY(0.2vh);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}