// Copyright (C) 2017-2022 Smart code 203358507

.search-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 3.5rem;
    border: var(--focus-outline-size) solid transparent;
    background-color: var(--color-placeholder-background);

    .search-input {
        flex: 1;
        max-height: 1.2em;
        margin-right: 1rem;
        font-size: 1.1rem;
        color: var(--color-placeholder-text);
    }

    .icon {
        flex: none;
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--color-placeholder-background);
    }
}