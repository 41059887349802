// Copyright (C) 2017-2022 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/Slider/styles.less') {
    slider-track-before: track-before;
    slider-thumb: thumb;
}

.seek-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover, &:global(.active) {
        .slider:not(:global(.disabled)) {
            .slider-thumb {
                fill: @color-surface-light5;
            }
        }
    }

    &:hover {
        .slider:not(:global(.disabled)) {
            .slider-track-before {
                transition: background-color 0s 100ms;
            }

            .slider-thumb {
                transition: fill 0s 100ms;
            }
        }
    }

    .label {
        flex: none;
        max-width: 5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        direction: rtl;
        text-align: left;
        color: @color-surface-light5;
    }

    .slider {
        flex: 1;
        align-self: stretch;
        margin: 0 var(--thumb-size);

        .slider-thumb {
            fill: transparent;
        }
    }
}