// Copyright (C) 2017-2022 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/MetaRow/styles.less') {
    meta-item: meta-item;
}

:import('~stremio/common/MetaRow/MetaRowPlaceholder/styles.less') {
    meta-item-placeholder: meta-item;
}

.board-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .board-content-container {
        flex: 1;
        align-self: stretch;
        background-color: @color-background-dark2;

        .board-content {
            width: 100%;
            height: 100%;
            overflow-y: auto;

            .board-row {
                margin: 4rem 2rem;
            }
        }
    }

    .board-warning-container {
        flex: none;
        align-self: stretch;
    }
}

@media only screen and (max-width: @large) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+10) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+9) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @normal) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+9) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+8) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @medium) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+8) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+7) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @small) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+7) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+6) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @xsmall) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+6) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+5) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @xxsmall) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+5) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+4) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .board-container {
        position: relative;
        z-index: 0;

        .board-content-container {
            &:only-child {
                .board-content {
                    height: 100%;
                }
            }
            
            .board-content {
                height: calc(100% - 4rem);

                .board-row {
                    margin: 2rem 1rem;
                }

                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+4) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+3) {
                            display: none;
                        }
                    }
                }
            }
        }
        
        .board-warning-container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: var(--vertical-nav-bar-size);
            height: 4rem;
        }
    }
}