// Copyright (C) 2017-2022 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.addon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1.5rem;
    background-color: @color-background;
    cursor: inherit;

    .logo-container {
        flex: none;
        width: 6rem;
        height: 6rem;
        background-color: @color-background-light1;

        .logo {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0.5rem;
            object-fit: contain;
            object-position: center;
        }

        .icon {
            display: block;
            width: 100%;
            height: 100%;
            padding: 1rem;
            fill: @color-secondaryvariant1-light3;
        }
    }

    .info-container {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        padding: 0 0.5rem;

        .name-container {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            padding: 0 0.5rem;
            max-height: 3.6em;
            font-size: 1.6rem;
            color: @color-surface-light5-90;
        }

        .version-container {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            margin-top: 0.5rem;
            padding: 0 0.5rem;
            max-height: 2.4em;
            color: @color-surface-light5-60;
        }

        .types-container {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-top: 0.5rem;
            padding: 0 0.5rem;
            max-height: 2.4em;
            color: @color-surface-light5-40;
            text-transform: capitalize;
        }

        .description-container {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-top: 0.5rem;
            padding: 0 0.5rem;
            max-height: 4.8em;
            color: @color-surface-light5-90;
        }
    }

    .buttons-container {
        flex: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 17rem;

        .action-buttons-container {
            flex: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }

        .install-button-container, .configure-button-container, .uninstall-button-container, .share-button-container {
            flex: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            height: 4rem;
            padding: 0 1rem;

            .icon {
                flex: none;
                width: 2rem;
                height: 2rem;
            }

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                max-height: 2.4em;
                font-size: 1.2rem;
                font-weight: 500;
                text-align: center;
            }
        }

        .install-button-container {
            background-color: @color-accent3;

            &:hover {
                background-color: @color-accent3-light2;
            }

            .label {
                color: @color-surface-light5;
            }
        }

        .configure-button-container {
            flex: none;
            background-color: @color-accent3;

            &:hover {
                background-color: @color-accent3-light2;
            }

            .icon {
                fill: @color-surface-light5;
            }
        }

        .uninstall-button-container {
            outline-color: @color-background-light3;
            outline-style: solid;

            &:hover {
                background-color: @color-background-light2;
            }

            .label {
                color: @color-surface-light5-90;
            }
        }

        .share-button-container {
            &:hover {
                .icon {
                    fill: @color-secondaryvariant1-light1;
                }

                .label {
                    text-decoration: underline;
                    color: @color-secondaryvariant1-light2;
                }
            }

            .icon {
                fill: @color-secondaryvariant1-dark1-60;
            }

            .label {
                color: @color-secondaryvariant1-90;
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .addon-container {
        flex-wrap: wrap;

        .info-container {
            margin-left: 0.5rem;
            padding: 0;
        }

        .buttons-container {
            flex: 0 1 100%;
            width: auto;
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .share-button-container {
                flex: none;

                .icon {
                    margin-right: 0;
                }

                .label {
                    display: none;
                }
            }

            .install-button-container, .uninstall-button-container {
                flex-basis: 100%;
                margin-right: 1rem;
            }
        }
    }
}