// Copyright (C) 2017-2022 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1.5em;
    
    &:global(.selected) {
        background-color: @color-background;

        .icon {
            display: block;
        }
    }

    &:hover, &:focus {
        background-color: @color-background-light2;
    }

    .label {
        flex: 1;
        font-weight: 400;
        color: @color-surface-light5-90;
    }

    .icon {
        flex: none;
        display: none;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        margin-left: 1rem;
        background-color: @color-accent3-90;
    }
}