// Copyright (C) 2017-2022 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.search-bar-container {
    --search-bar-size: calc(var(--horizontal-nav-bar-size) - 1.2rem);
    display: flex;
    flex-direction: row;
    height: var(--search-bar-size);
    border-radius: var(--search-bar-size);
    background-color: @color-background-light2;

    &:hover {
        background-color: @color-background-light3;
    }

    .search-input {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0.5rem 0 1.5rem;
        font-weight: 500;
        color: @color-secondaryvariant1-light1;
        cursor: text;

        &::placeholder, .placeholder-label {
            max-height: 1.2em;
            opacity: 1;
            color: @color-secondaryvariant1-light1-90;
        }
    }

    .submit-button-container {
        flex: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: var(--search-bar-size);
        height: var(--search-bar-size);

        &:hover {
            .icon {
                fill: @color-secondaryvariant2-light1-90;
            }
        }

        .icon {
            flex: none;
            width: 1.7rem;
            height: 1.7rem;
            fill: @color-secondaryvariant1-90;
        }
    }
}